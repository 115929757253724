
@import "bourbon";
/* ---------- FONTS ---------- */
//пример подключения шрифта (для быстрого подкл. используйте snippet iff)
@include font-face("OpenSansRegular", "../fonts/OpenSansRegular/OpenSansRegular", $file-formats: eot woff ttf);
@include font-face("GothamProRegular", "../fonts/GothamProRegular/GothamProRegular", $file-formats: eot woff ttf);
@include font-face("GothamProBold", "../fonts/GothamProBold/GothamProBold", $file-formats: eot woff ttf);
// @include font-face("LatoBold", "../fonts/LatoBold/LatoBold", $file-formats: eot woff ttf);
// @include font-face("CalibriRegular", "../fonts/CalibriRegular/CalibriRegular", $file-formats: eot woff ttf);
@include font-face("UniSansHeavyCaps", "../fonts/UniSansHeavyCaps/UniSansHeavyCaps", $file-formats: eot woff ttf);
@include font-face("BrutalTypeMedium", "../fonts/BrutalTypeMedium/BrutalTypeMedium", $file-formats: eot woff ttf);
@include font-face("UniSansThinCaps", "../fonts/UniSansThinCaps/UniSansThinCaps", $file-formats: eot woff ttf);
// @include font-face("LatoHeavy", "../fonts/LatoHeavy/LatoHeavy", $file-formats: eot woff ttf);
// @include font-face("LatoMedium", "../fonts/LatoMedium/LatoMedium", $file-formats: eot woff ttf);
// @include font-face("LatoRegular", "../fonts/LatoRegular/LatoRegular", $file-formats: eot woff ttf);
// @include font-face("LatoSemiBold", "../fonts/LatoSemiBold/LatoSemiBold", $file-formats: eot woff ttf);

/* ---------- @IMPORT VARIABLES ---------- */
@import "_variables.scss";


/* ---------- @IMPORT BUTTONS ---------- */
@import "_buttons.scss";
/*	----------PRELOADER STYLES---------- */



/* ----------MAIN STYLE---------- */
body {
	font-family: "OpenSansRegualr", Helvetica, Arial, sans-serif;
	font-size: 14px;
	line-height: 1.42857143;
	min-width: 500px;
	color: #333333;
	position: relative;
	background-color: #ffffff;
}

////Удалить или закоментировать при адаптивном дизайне!//////////////
.container {width: 960px;}
//////////////////////////////////////////////////////////////////////

.hidden {
	display: none;
}

.wrapper {
	margin: 0 auto;
}

p {
	margin: 0;
	padding: 0;
}

br.sm {display: none;}
br.xs {display: none;}

//validatorGM styles//
 form input.error {
 	box-shadow: inset 0px 0px 20px 1px rgba(255,0,0,0.3);
 	border: 2px solid red !important;}
 form input.not_error {
 	box-shadow: inset 0px 0px 20px 1px rgba(100,255,100,0.3);
 	border: 2px solid #99FF99 !important;}

/* ----------IMPORT HEADER STYLES---------- */
@import "_header.scss";

/* ----------TYPOGRAPHY---------- */


/* ----------SECTIONS---------- */

.comments-section {
	padding-top: 64px;
	text-align: center;
	background-color: #f2f2f2;
	padding-bottom: 80px;
	.comments-title {
		text-align: center;
		color: #1f1b1b;
		font-size: 29px;
		margin-bottom: 30px;
		font-family: UniSansHeavyCaps;
	}
}

.comments {
  display: block;
  margin: 0 auto ;
}
.comments .comments-wrapper.fb-comments {
    margin-right: 20px;
}
.comments .comments-wrapper {
    display: inline-block;
    vertical-align: top;
    width: 452px;
    height: auto;
    box-sizing: border-box;
    margin: 0;
    padding: 15px;
    background-color: #fff;

}
.fb_iframe_widget_fluid {
    display: inline;
}
.fb_iframe_widget {
    display: inline-block;
    position: relative;
}
.com-vk-fb {
  display:block;
  margin:0 auto;
  width: 950px;
}
#vk_groups, #vk_groups iframe {
    width: 100% !important;
  }

/* ----------IMPORT FOOTER STYLES---------- */
@import "_footerGM.scss";

/* ----------IMPORT MEDIA STYLES---------- */
@import "_media.scss";