#soc {
  margin: 0 auto;
  width: 320px;
  height: 40px;
  border-radius: 3px;
  background-color: transparent;
  }

#soc1 {
  width: 205px;
  height: 100%;
  float: left;
  line-height: 42px;
  padding-left: 0;
  margin-left: -25px; }

#soc2 {
  width: 130px;
  height: 100%;
  float: left;
  line-height: 28px; }

.subm1 {
	width: 285px;
	height: 61px;
	text-shadow: 0.5px 0.8px 0 rgba(255, 255, 251, 0.93);
	color: black;
	font-family: "UniSansHeavyCaps";
	font-size: 20px;
	border: none;
	border-radius: 2px;
	background: linear-gradient(to bottom,#fffd8a 0%,#f5e43a 100%);
	cursor: pointer;
	transition: all 0.3s linear;
}
.subm1:hover,
.subm1:active,
.subm1:focus {
	transform: scale(1.05);
}

.btn-wrap {
  margin-top: 40px;
}

.btn {
  padding-top: 23px;
  display: block;
  margin: 0 auto;
  text-align: center;
  width: 369px;
  height: 73px;
  text-shadow: 0.5px 0.8px 0 rgba(255, 255, 251, 0.93);
  color: black;
  font-family: "UniSansHeavyCaps";
  font-size: 20px;
  border: none;
  border-radius: 2px;
  background: linear-gradient(to bottom,#f5e344 0%,#ebcd1c 100%);
  cursor: pointer;
  transition: all 0.3s linear;
}
.btn:hover,
.btn:active,
.btn:focus {
  text-decoration: none;
  transform: scale(1.05);
  color: #000;
}