/*********************************************\
		Desktop First Method
\*********************************************/
/* Medium Devices, Notebooks */
@media only screen and (max-width : 1130px) {

}


/* Small Devices, Tablets */
@media only screen and (max-width : 960px) {
 .container {width: 720px;}
 br.sm {display: block;}
 br.xs {display: none;}
	.main-header {
		padding-top: px;
		padding-bottom: 60px;
		.date {
			font-size: 22px;
			line-height: 28px;
		}
		.date::after {
			height: 101px;
			top: -10px;
		}
		.main-title {
			font-size: 20px;
			line-height: 25px;
			letter-spacing: 1px;
			span {
				padding-left: 5px;
				width: 26px;
				height: 27px;
				background-size: 26px;
			}
		}
		.video {
			margin-top: 50px;
			width: 620px;
			height: 330px;
		}
	}
	.main-header::before {
		height: 310px;
	}


	.comments-section {
		padding-top: 50px;
		.comments-title {
			font-size: 26px;
			line-height: 30px;
		}
		.comments-block {
			text-align: center;
		}
	}

	.comments {
		text-align: center;
		.comments-wrapper.fb-comments {
			margin-right: 0;
		}
		.comments-wrapper {
			float: none;
			margin-bottom: 33px;
		}
	}

}




/* Extra Small Devices, Phones */
@media only screen and (max-width : 720px) {
	.container {
		width: 500px;
		overflow: hidden;
	}
	br.sm {display: none;}
	br.xs {display: block;}

	/*Disable Animation on Mobile Devices*/
	.animated {
		/*CSS transitions*/
		-o-transition-property: none !important;
		-moz-transition-property: none !important;
		-ms-transition-property: none !important;
		-webkit-transition-property: none !important;
		transition-property: none !important;
		/*CSS transforms*/
		-o-transform: none !important;
		-moz-transform: none !important;
		-ms-transform: none !important;
		-webkit-transform: none !important;
		transform: none !important;
		/*CSS animations*/
		-webkit-animation: none !important;
		-moz-animation: none !important;
		-o-animation: none !important;
		-ms-animation: none !important;
		animation: none !important;
	}

	.main-header {
		padding-bottom: 45px;
		padding-top: 24px;
		text-align: center;
		.date {
			br {display: none;}
			font-size: 21px;
			line-height: 26px;
			margin-bottom: 20px;
		}
		.date::after {
			height: 1px;
			width: 101px;
			top: 37px;
			right: 50%;
			margin-right: -50px;
		}
		.main-title {
			font-size: 16px;
			line-height: 24px;
			padding: 0 50px;
			span {
				padding-top: 3px;
			}
		}
		.video {
			margin-top: 15px;
			width: 341px;
			height: 182px;
		}
		.btn {
			width: 317px;
			font-size: 19px;
		}
	}
	.main-header::before {
		height: 250px;
	}




	.comments-section {
			.comments-title-sm {
				font-size: 21px;
				line-height: 26px;
				span {
					font-family: UniSansHeavyCaps;
				}
			}
		}

}


/* Custom, iPhone Retina */
@media only screen and (max-width : 340px) {
		.container {
		padding: 0;
		overflow: hidden;
		width: 320px;}
}


/*********************************************\
		Mobile First Method
\*********************************************/


/* Extra Small Devices, Phones */
@media only screen and (min-width : 320px) {

}


/* Small Devices, Tablets */
@media only screen and (min-width : 720px) {

}


/* Medium Devices, Desktops */
@media only screen and (min-width : 960px) {

}


/* Large Devices, Wide Screens */
@media only screen and (min-width : 1130px) {

}