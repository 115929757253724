.main-header {
	position: relative;
	padding-top: 75px;
	padding-bottom: 72px;
	// background: #fff url(../img/header-bg.png) 50% 0 no-repeat;
	.date {
		display: inline-block;
		position: relative;
		font-size: 28px;
		text-transform: uppercase;
		color: white;
		font-family: UniSansHeavyCaps;
		span {
			padding: 0 5px;
			background-color: #e0ca3d;
			color: black;
			font-family: UniSansThinCAPS;
		}
	}
	.date::after {
		content: '';
		position: absolute;
		display: block;
		right: -31px;
		top: -35px;
		width: 1px;
		height: 156px;
		// opacity: 0.43;
		background-color: #818183;
	}
	.main-title {
		padding-top: 2px;
		margin: 0;
		color: white;
		font-family: BrutalTypeMedium;
		font-size: 25px;
		text-transform: uppercase;
		span {
			display: inline-block;
			color: black;
			width: 32px;
			height: 33px;
			text-align: center;
			background: url(../img/num-bg.png) 0 0 no-repeat;
		}
	}

	.video {

		overflow: hidden;
		position: relative;
		margin: 0 auto;
		margin-top: 84px;
		width: 940px;
		height: 500px;
		// background-color: #2b2b2b;
		// background: url(../img/videobg2.png) 50% 50% no-repeat;
		// background-size: cover;
		border-radius: 5px;
		box-shadow: 1.1px 1.7px 36.1px 1.9px rgba(45, 45, 45, 0.53);
		margin-bottom: 10px;
	}
	.likes {
		display: block;
		.like-title {
			position: relative;
			display: inline-block;
			color: black;
			font-family: "BrutalTypeMedium";
			font-size: 17px;
			margin-bottom: 3px;
		}
		.like-title::after {
			content: '';
			position: absolute;
			width: 14px;
			height: 24px;
			top: 10px;
			right: -20px;
			background: url(../img/like-arr.png) 0 0 no-repeat;
		}
	}
}

.main-header::before {
	content: '';
	position: absolute;
	background: #020202 url(../img/header-bg.png) 50% 0 no-repeat;
	left: 0;
	top: 0;
	width: 100%;
	height: 478px;
	z-index: -1;
}